<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2024-07-05 09:54:25
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2024-07-09 13:45:49
 * @FilePath: \official_website\src\views\science\componets\Doctor.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<!-- 吉博士 -->
<template>
  <div id="doctor">
    <ul class="video-box">
      <!-- <li class="video-item">
        <div
          class="video-img"
          style="
            background-image: url('http://hnpinxin-webapi.biopic.top/webapi/images/20231106092054.jpg');
          "
        ></div>
        <div class="title-box">
          <div class="title">
            品信生物祝贺江西省医学会病理学分会第48次学术年会顺利召开，“吉米娜”AI系统精彩亮相！
          </div>
          <div class="time">2023-04-03</div>
        </div>
      </li> -->
      <li class="video-item" v-for="item in videoList">
        <div
          class="video-img"
          :style="{'background-image': `url(${item.titleImage}`}"
        ></div>
        <div class="title-box">
          <div class="title" @click="tapVideo(item.id)">
            {{ item.title }}
          </div>
          <div class="time">{{ item.createTime ? item.createTime.split(" ")[0] : "" }}</div>
        </div>
      </li>
    </ul>
    <div class="pagination-box">
      <el-pagination
        background
        layout="total, prev, pager, next"
        :total="count"
        :current-page.sync="currentPage"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
  </div>
</template>
    
<script>
import api from "../../../api/api";
export default {
  name: "",
  props: {},
  components: {},
  data() {
    return {
      videoList: [], // 分页新闻列表 
      count: 0, // 新闻列表数量
      currentPage: 1, // 当前页码
    };
  },
  created() {
    this.getVideoList();
    this.getVideoCounts();
  },
  mounted() {
    this.$nextTick(() => {
      new this.$wow.WOW({
        boxClass: "wow", // 动画元件css类（默认为wow）
        animateClass: "animate__animated", //动画css类（默认为animated）
        offset: 0, //到元素距离触发动画（当默认为0）
        mobile: true, //在移动设备上触发动画（默认为true）
        live: true, //对异步加载的内容进行操作（默认为true）
      }).init();
    });
  },
  watch: {},
  methods: {
     // 分页获取视频列表
    getVideoList(page = 1) {
      api
        .getVideoByPage(page)
        .then((res) => {
          // console.log(res.data);
          if (res.data.code == 200) {
            let result = res.data.models;
            result.forEach((item) => {
              let times = item.createTime ? item.createTime.split(" ")[0] : "";
              let moment = times ? times.split("-") : [];
              item.year = moment[0];
              item.month = moment[1];
              item.day = moment[2];
            });
            this.videoList = result;
            // console.log(this.videoList);
          }
        })
        .catch((error) => {});
    },
    // 获取视频总条数
    getVideoCounts() {
      api
        .getVideoCount()
        .then((res) => {
          if (res.data.code == 200) {
            this.count = res.data.models[0];
          }
        })
        .catch((error) => {});
    },
    tapVideo(id) {
      this.$router.push({
        path: `/doctorDetail/${id}`, 
      });
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getVideoList(val);
    },
  },
};
</script>
    
<style lang="scss">
#doctor {
  .video-box {
    padding: 0 5%;
    display: flex;
    flex-wrap: wrap;
    .video-item {
      width: 47%;
      margin-right: 5%;
      border: 1px solid #ededed;
      cursor: pointer;
      margin-bottom: 5%;
      &:nth-of-type(2n) {
        margin-right: 0;
      }
      .video-img {
        height: 18rem;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 102%;
        &:hover {
          animation-name: scaleDraw; /*关键帧名称*/
          animation-timing-function: ease; /*动画的速度曲线*/
          animation-duration: 2s; /*动画所花费的时间*/
        }
      }

      @keyframes scaleDraw {
        /*定义关键帧、scaleDrew是需要绑定到选择器的关键帧名称*/
        0% {
          background-size: 110%;
        }
        100% {
          background-size: 102%;
        }
      }

      // img {
      //   width: 100%;
      // }
      .title-box {
        padding: 1.25rem;
        .title {
          font-size: 1.2rem;
          font-family: "SourceHanSansSC-Medium";
          margin-bottom: 0.625rem;
        }
        .time {
          color: #666666;
          font-family: "OPlusSans3-Regular";
        }
      }
    }
  }
  .pagination-box {
    display: flex;
    justify-content: center;
    margin-bottom: 3rem;
    .el-pager li,
    .el-pagination button {
      height: 2.25rem;
      line-height: 2.25rem;
      min-width: 2.25rem;
      font-size: 0.95rem;
    }
    .el-pagination .btn-prev .el-icon,
    .el-pagination .btn-next .el-icon {
      font-size: 0.95rem;
    }
  }
}
</style>